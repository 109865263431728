<template>
  <BetSection/>

  <FAQ style="margin-top:50px"/>
</template>

<script>
import BetSection from "@/components/BetSection.vue";
import FAQ from "@/components/FAQ.vue";

export default {
  components: {
    BetSection, FAQ
  },
  setup() {

  }
}
</script>