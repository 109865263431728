import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import {ref} from "vue";
import {web3} from "@/web3/contracts";

const onboard = Onboard({
    wallets: [injectedModule()],
    chains: [
        {
            id: 56,
            token: 'BNB',
            label: 'BNB Chain',
            rpcUrl: 'https://bsc-dataseed1.binance.org/'
        }
    ],
    appMetadata: {
        name: 'SilverName bet',
        description: 'Make a bet on winning or losing SilverName challenge',
        recommendedInjectedWallets: [
            { name: 'MetaMask', url: 'https://metamask.io' },
            { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
        ]
    }
})

const currentAccount = ref("")

const doOnboard = async () => {
    const connectedWallets = await onboard.connectWallet();
    console.log("Connected wallets:", connectedWallets.length);

    if (connectedWallets && connectedWallets[0]) {
        web3.setProvider(connectedWallets[0].provider);
        const accounts = await web3.eth.getAccounts();
        currentAccount.value = accounts[0]
    }
    else {
        throw new Error("Cant get current account")
    }
}

export { doOnboard, currentAccount }