<template>
  <article class="bet-card">
    <header>
      <h1>Возьмёт ли <a href="https://www.twitch.tv/silvername"><img src="../assets/silver.webp" alt="SilverName"></a> 17к рейтинга до конца сезона? </h1>
    </header>
    <h4 style="text-align:center">{{betCountdown}}</h4>
    <section class="bet-container">
      <div class="bet-part left-bet-part">
        <h2>Да</h2>
        <div class="bet-stat-container">
          <div class="bet-stat-info">
            <div data-tooltip="Всего поставлено">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 50 50" ><path d="M 10.417969 5 L 2.0820312 22.949219 L 25 45 L 47.917969 22.949219 L 39.582031 5 L 10.417969 5 z M 11.693359 7 L 38.306641 7 L 45.501953 22.496094 L 25 42.224609 L 4.4980469 22.496094 L 11.693359 7 z M 12.957031 10.042969 L 13 16 L 22 16 L 22 19.490234 C 13.875 19.749234 10.417969 21.046969 10.417969 22.542969 C 10.417969 24.038969 15.449 25.694 22 26 L 22 37 L 28 37 L 28 26 C 34.551 25.694 39.582031 24.038969 39.582031 22.542969 C 39.582031 21.046969 36.125 19.874234 28 19.490234 L 28 16 L 37 16 L 36.957031 10.042969 L 12.957031 10.042969 z M 22 20.375 L 22 23 C 23 23.063 23.917 23.082031 25 23.082031 C 26.083 23.082031 27 23.062 28 23 L 28 20.5 C 33.389 20.76 37 21.158 37 22.25 C 37 23.545 31.903 24.625 25 24.625 C 18.097 24.625 13 23.67 13 22.375 C 13 21.283 16.611 20.635 22 20.375 z"/></svg>
              <span>{{totalWinBets}}</span>
            </div>
            <div data-tooltip="Коэффициент">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path fill-rule="evenodd" d="M5 10h.1A5.006 5.006 0 009 13.9V16H7v2h6v-2h-2v-2.1a5.006 5.006 0 003.9-3.9h.1a3 3 0 003-3V4h-3V2H5v2H2v3a3 3 0 003 3zm2-6h6v5a3 3 0 11-6 0V4zm8 2v2a1 1 0 001-1V6h-1zM4 6h1v2a1 1 0 01-1-1V6z" clip-rule="evenodd"></path></g></svg>
              <span>{{winCoeff}}</span>
            </div>
            <div data-tooltip="Сколько людей поставило">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path fill-rule="evenodd" d="M7 2a4 4 0 00-1.015 7.87c-.098.64-.651 1.13-1.318 1.13A2.667 2.667 0 002 13.667V18h2v-4.333c0-.368.298-.667.667-.667.908 0 1.732-.363 2.333-.953.601.59 1.425.953 2.333.953.369 0 .667.299.667.667V18h2v-4.333A2.667 2.667 0 009.333 11c-.667 0-1.22-.49-1.318-1.13A4.002 4.002 0 007 2zM5 6a2 2 0 104 0 2 2 0 00-4 0z" clip-rule="evenodd"></path><path d="M14 11.83V18h4v-3.75c0-.69-.56-1.25-1.25-1.25a.75.75 0 01-.75-.75v-.42a3.001 3.001 0 10-2 0z"></path></g></svg>
              <span>{{winBettersAmount}}</span>
            </div>
            <div data-tooltip="Максимальная ставка">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path d="M9 2h2v3H9V2zM15.884 3.309l1.732 1-1.5 2.598-1.732-1 1.5-2.598zM2.384 4.309l1.732-1 1.5 2.598-1.732 1-1.5-2.598z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14 8v2h4v8H2v-6h4V8h8zm-2 2v2h4v4H4v-2h4v-4h4z"></path></g></svg>
              <span>{{maxWinBet}}</span>
            </div>
          </div>
          <div class="bet-stat-main-info">
            <span>{{winPercent}}%</span>
            <div class="progress" :style="`width: ${winPercent}%;`"/>
          </div>
        </div>
        <form>
          <fieldset role="grid">
            <input v-model="betWin" placeholder="USDT"/>
            <input @click.prevent="winClick" :disabled="!betWin" type="submit"  value="Поставить" />
          </fieldset>
          <small v-if="winError">
            {{winError}}
          </small>
          <p v-if="alreadyBetOnWin">Уже поставил {{alreadyBetOnWin}} USDT</p>
        </form>
      </div>
      <div class="bet-part right-bet-part">
        <h2>Нет</h2>
        <div class="bet-stat-container">
          <div class="bet-stat-main-info">
            <span>{{losePercent}}%</span>
            <div class="progress" :style="`width: ${losePercent}%;`"/>
          </div>
          <div class="bet-stat-info">
            <div data-tooltip="Всего поставлено">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 50 50" ><path d="M 10.417969 5 L 2.0820312 22.949219 L 25 45 L 47.917969 22.949219 L 39.582031 5 L 10.417969 5 z M 11.693359 7 L 38.306641 7 L 45.501953 22.496094 L 25 42.224609 L 4.4980469 22.496094 L 11.693359 7 z M 12.957031 10.042969 L 13 16 L 22 16 L 22 19.490234 C 13.875 19.749234 10.417969 21.046969 10.417969 22.542969 C 10.417969 24.038969 15.449 25.694 22 26 L 22 37 L 28 37 L 28 26 C 34.551 25.694 39.582031 24.038969 39.582031 22.542969 C 39.582031 21.046969 36.125 19.874234 28 19.490234 L 28 16 L 37 16 L 36.957031 10.042969 L 12.957031 10.042969 z M 22 20.375 L 22 23 C 23 23.063 23.917 23.082031 25 23.082031 C 26.083 23.082031 27 23.062 28 23 L 28 20.5 C 33.389 20.76 37 21.158 37 22.25 C 37 23.545 31.903 24.625 25 24.625 C 18.097 24.625 13 23.67 13 22.375 C 13 21.283 16.611 20.635 22 20.375 z"/></svg>
              <span>{{totalLoseBets}}</span>
            </div>
            <div data-tooltip="Коэффициент">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path fill-rule="evenodd" d="M5 10h.1A5.006 5.006 0 009 13.9V16H7v2h6v-2h-2v-2.1a5.006 5.006 0 003.9-3.9h.1a3 3 0 003-3V4h-3V2H5v2H2v3a3 3 0 003 3zm2-6h6v5a3 3 0 11-6 0V4zm8 2v2a1 1 0 001-1V6h-1zM4 6h1v2a1 1 0 01-1-1V6z" clip-rule="evenodd"></path></g></svg>
              <span>{{loseCoeff}}</span>
            </div>
            <div data-tooltip="Сколько людей поставило">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path fill-rule="evenodd" d="M7 2a4 4 0 00-1.015 7.87c-.098.64-.651 1.13-1.318 1.13A2.667 2.667 0 002 13.667V18h2v-4.333c0-.368.298-.667.667-.667.908 0 1.732-.363 2.333-.953.601.59 1.425.953 2.333.953.369 0 .667.299.667.667V18h2v-4.333A2.667 2.667 0 009.333 11c-.667 0-1.22-.49-1.318-1.13A4.002 4.002 0 007 2zM5 6a2 2 0 104 0 2 2 0 00-4 0z" clip-rule="evenodd"></path><path d="M14 11.83V18h4v-3.75c0-.69-.56-1.25-1.25-1.25a.75.75 0 01-.75-.75v-.42a3.001 3.001 0 10-2 0z"></path></g></svg>
              <span>{{loseBettersAmount}}</span>
            </div>
            <div data-tooltip="Максимальная ставка">
              <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1hrsqw6-1 ihOSMR"><g><path d="M9 2h2v3H9V2zM15.884 3.309l1.732 1-1.5 2.598-1.732-1 1.5-2.598zM2.384 4.309l1.732-1 1.5 2.598-1.732 1-1.5-2.598z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14 8v2h4v8H2v-6h4V8h8zm-2 2v2h4v4H4v-2h4v-4h4z"></path></g></svg>
              <span>{{maxLoseBet}}</span>
            </div>
          </div>
        </div>
        <form>
          <fieldset role="grid">
            <input v-model="betLose" placeholder="USDT" />
            <input @click.prevent="loseClick" :disabled="!betLose" type="submit" value="Поставить" />
          </fieldset>
          <small v-if="loseError">
            {{loseError}}
          </small>
          <p v-if="alreadyBetOnLose">Уже поставил {{alreadyBetOnLose}} USDT</p>
        </form>
      </div>
    </section>
  </article>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { betContract } from "@/web3/contracts";
import { doBetOnLose, doBetOnWin, loseError, winError } from "@/composables/workWithChain";
import { currentAccount } from "@/web3/onboarding";

export default {
  setup() {
    const totalWinBets = ref(0);
    const totalLoseBets = ref(0);
    const betWin = ref("");
    const betLose = ref("");
    const maxWinBet = ref(0);
    const maxLoseBet = ref(0);
    const winBettersAmount = ref(0);
    const loseBettersAmount = ref(0);
    const betCountdown = ref("");
    const alreadyBetOnWin = ref("");
    const alreadyBetOnLose = ref("");

    const winPercent = computed(() => {
      const sum = totalWinBets.value + totalLoseBets.value
      if (sum)
        return Math.round(totalWinBets.value / sum * 100);
      else
        return 0;
    })
    const losePercent = computed(() => {
      const sum = totalWinBets.value + totalLoseBets.value
      if (sum)
        return Math.round(totalLoseBets.value / sum * 100);
      else
        return 0;
    })
    const winCoeff = computed(() => {
      const sum = totalWinBets.value + totalLoseBets.value * 0.9
      if (sum)
        return (sum / totalWinBets.value).toFixed(2);
      else
        return 0;
    });
    const loseCoeff = computed(() => {
      const sum = totalLoseBets.value + totalWinBets.value * 0.9
      console.log(sum)
      if (sum)
        return (sum / totalLoseBets.value).toFixed(2);
      else
        return 0;
    });

    onMounted(async () => {
      countdown();
      setInterval(countdown, 1_000);

      await update();
      setInterval(update, 10_000);
    });

    const winClick = async () => {
      await doBetOnWin(betWin.value)
      betWin.value = ""
    }

    const loseClick = async () => {
      await doBetOnLose(betLose.value)
      betLose.value = ""
    }

    async function update() {
      let _totalWinBets = await betContract.methods.totalWinBets().call()
      _totalWinBets /= BigInt(1e18)

      let _totalLoseBets = await betContract.methods.totalLoseBets().call()
      _totalLoseBets /= BigInt(1e18)

      let _maxWinBet = await betContract.methods.maxWinBet().call();
      _maxWinBet /= BigInt(1e18)

      let _maxLoseBet = await betContract.methods.maxLoseBet().call();
      _maxLoseBet /= BigInt(1e18)

      const _winBettersAmount = await betContract.methods.winBettersAmount().call();
      const _loseBettersAmount = await betContract.methods.loseBettersAmount().call();

      if (currentAccount.value) {
        let _alreadyBetOnWin = await betContract.methods.winBets(currentAccount.value).call();
        _alreadyBetOnWin /= BigInt(1e18)


        let _alreadyBetOnLose = await betContract.methods.loseBets(currentAccount.value).call();
        _alreadyBetOnLose /= BigInt(1e18)

        alreadyBetOnWin.value = Number(_alreadyBetOnWin)
        alreadyBetOnLose.value = Number(_alreadyBetOnLose)
      }

      totalWinBets.value = Number(_totalWinBets)
      totalLoseBets.value = Number(_totalLoseBets)
      maxWinBet.value = Number(_maxWinBet);
      maxLoseBet.value = Number(_maxLoseBet);
      winBettersAmount.value = Number(_winBettersAmount)
      loseBettersAmount.value = Number(_loseBettersAmount)
    }

    function countdown() {
      const now = new Date();
      const target = new Date("2024-08-06T00:00:00");

      const diff = target - now;

      const seconds = Math.floor(diff / 1000) % 60;
      const minutes = Math.floor(diff / 60000) % 60;
      const hours = Math.floor(diff / 3600000) % 24;
      const days = Math.floor(diff / 86400000);

      betCountdown.value = `${days}д ${hours}ч ${minutes}м ${seconds}с`;
    }

    watch(betWin, (newVal) => {
      if (typeof newVal === 'string') {
        betWin.value = newVal.replace(/[^0-9]/g, '');
      } else {
        betWin.value = "";
      }
    });

    watch(betLose, (newVal) => {
      if (typeof newVal === 'string') {
        betLose.value = newVal.replace(/[^0-9]/g, '');
      } else {
        betLose.value = "";
      }
    });

    return {
      totalWinBets, totalLoseBets, betWin, betLose, winPercent, losePercent, winCoeff, loseCoeff, maxWinBet, maxLoseBet, winBettersAmount, loseBettersAmount,
      betCountdown,
      alreadyBetOnWin, alreadyBetOnLose,
      winClick, loseClick, winError, loseError };
  }
}
</script>

<style scoped>
.bet-card {
  margin: auto;
}

.bet-container {
  display: flex;
  justify-content: space-between;
}

.bet-part {
  width: 50%;
}

.left-bet-part {
  padding-right: 0.5rem;
  border-right: 1px solid #333;
}

.left-bet-part h2,
.left-bet-part svg
{
  color: rgb(56, 122, 255);
}

.left-bet-part .bet-stat-main-info span {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(56, 122, 255);
}

.left-bet-part .bet-stat-main-info {
  text-align: right;
}

.right-bet-part .bet-stat-main-info {
  text-align: left;
}

.right-bet-part h2,
.right-bet-part svg
{
  color: rgb(245, 0, 155);
}

.right-bet-part .bet-stat-main-info span {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(245, 0, 155);
}

.right-bet-part {
  padding-left: 0.5rem;
  border-left: 1px solid #333;
}

.bet-part h2 {
  text-align: center;
}

.bet-stat-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bet-stat-info div {
  display: flex;
  align-items: center;
  border-bottom: unset;
  cursor: unset;
}

.bet-stat-info div span {
  margin-left: 5px;
}

.bet-stat-main-info {
  width: 50%;
}

.progress {
  height: 10px;
  min-width: 10px;
  border-radius: 0.6rem;
  margin-top: 0.5rem;
}

.left-bet-part .progress {
  background: rgb(56, 122, 255);
  margin-left: auto;
}

.right-bet-part .progress {
  background: rgb(245, 0, 155);
  margin-right: auto;
}

.bet-part form {
  padding-top: 1.5rem;
}

fieldset {
  margin-bottom: unset;
}

</style>