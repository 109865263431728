<template>
  <article>
    <header><h3>Ответы на вопросы</h3></header>
    <details>
      <summary>Как это работает?</summary>
      <p>Вы можете сделать ставку на победу или поражение SilverName'а в челлендже на биткоин. Ставки принимаются до 6 августа. Когда сезон закончится (20 августа) ваши выигранные деньги с процентом будут возвращены на тот же кошелек, с которого были отправлены. До этого момента они хранятся на верифицированном <a href="https://bscscan.com/address/0x257ddca5AC633f8cd4388cf55ba36f3F08fa6e56#code">смартконтракте</a>.</p>
    </details>
    <hr />
    <details>
      <summary>Это скам?</summary>
      <p>Нет, все транзакции проходят в блокчейне внутри верифицированного <a href="https://bscscan.com/address/0x257ddca5AC633f8cd4388cf55ba36f3F08fa6e56#code">смартконтракта</a> с открытам исходным кодом. Его можно прочесть, убедившись в честности.</p>
    </details>
    <hr />
    <details>
      <summary>В чем принимаются ставки?</summary>
      <p>Ставки принимаются в стейблкоине USDT в сети BSC(BEP20)</p>
    </details>
    <hr />
    <details>
      <summary>Коэффициент динамический?</summary>
      <p>Да, коэффициент динамический и может менятся до самого закрытия приема ставок. Это особенность ставок без централизованного букмекера, так достигается прозрачность и более высокие коэффициенты.</p>
    </details>
    <hr />
    <details>
      <summary>Когда заканчивыается прием ставок?</summary>
      <p>Прием ставок заканчивается за 2 недели до конца сезона, то есть 6 августа.</p>
    </details>
    <hr />
    <details>
      <summary>Куда отправлять вопросы и предложения?</summary>
      <p>Все вопросы и предложения отправляйте на почту <a href="mailto:mycryptotricks@gmail.com">mycryptotricks@gmail.com</a></p>
    </details>
  </article>
</template>
<script setup lang="ts">
</script>