import {ref} from "vue";
import {currentAccount, doOnboard} from "@/web3/onboarding";
import {betOnLose, betOnWin, usdtApprove, web3} from "@/web3/contracts";


const winError = ref("")
const loseError = ref("")

const doBetOnWin = async (usdtAmount) => {
    winError.value = "";

    if (!currentAccount.value) {
        try {
            await doOnboard();
        } catch (e) {
            winError.value = e.message;
            return;
        }
    }

    try {
        await changeChainToBscIfNeeded();
    } catch (e) {
        winError.value = e.message;
        return;
    }

    try {
        await usdtApprove(usdtAmount);
    } catch (e) {
        winError.value = e.message;
        return;
    }

    try {
        await betOnWin(usdtAmount);
    } catch (e) {
        winError.value = e.message;
    }
}

const doBetOnLose = async (usdtAmount) => {
    loseError.value = "";

    if (!currentAccount.value) {
        try {
            await doOnboard();
        } catch (e) {
            loseError.value = e.message;
            return;
        }
    }

    try {
        await changeChainToBscIfNeeded();
    } catch (e) {
        winError.value = e.message;
        return;
    }

    try {
        await usdtApprove(usdtAmount);
    } catch (e) {
        loseError.value = e.message;
        return;
    }

    try {
        await betOnLose(usdtAmount);
    } catch (e) {
        loseError.value = e.message;
    }
}

async function changeChainToBscIfNeeded() {
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId !== 56) {
        await web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: '0x38'}]
        });
    }
}

export { doBetOnWin, doBetOnLose, winError, loseError }